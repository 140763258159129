import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
// import { SpinnerCircular } from "spinners-react";
// import useDocumentTitle from "../components/useDocumentTitle";

const Order = () => {
  // useDocumentTitle("Not Found || React Personal Portfolio Template");
  const { id } = useParams();

  const [data, setData] = useState({ name: "", url: "#" });
  const [fetching, setFetching] = useState(true);

  const url =
    process.env.NODE_ENV === "production"
      ? "https://btp-ninja-api.herokuapp.com"
      : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(`${url}/api/orders/${id}`);
        setData(result.data);
        setFetching(false);
      } catch (err) {
        setTimeout(fetchData(), 250);
      }
    };

    fetchData();
  }, [id, url]);
  return (
    <div className="main-page-wrapper p0">
      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="https://images.unsplash.com/photo-1605440190093-b67e90c96235?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80"
            alt="media"
            className="w-100 illustration"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <div className="logo">
            <Link to="/">
              <div className="logo">
                <h3 style={{ fontWeight: "500" }}>btp.ninja</h3>
              </div>
            </Link>
          </div>
          {fetching === true ? (
            <></>
          ) : (
            <div>
              <h1 className="font-slab">Thank You {data.name}!</h1>

              <p className="font-rubik">
                Use below button to download Your order. The invoice has been
                emailed you separetrly.
              </p>

              <a
                href={data.url}
                className="back-home font-rubik d-flex align-items-center justify-content-center"
              >
                <span>Download</span>
                <img src="/images/icon/53.svg" alt="icon" />
              </a>
              <p style={{ fontSize: "15px", marginTop: "-15px" }}>
                You can download your order two times, link has been emailed to
                you.
              </p>
            </div>
          )}
        </div>

        {/* /.text-wrapper */}
      </div>
      {/* /.error-page */}
    </div>
  );
};

export default Order;
