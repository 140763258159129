import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import FormDemo from "../../components/form/FormDemo";
import { Link } from "react-router-dom";

const FancyTextBlock22 = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="row align-items-center">
        <div
          className="col-xl-5 col-lg-6 order-lg-last"
          data-aos="fade-left"
          data-aos-duration="1200"
        >
          <div className="text-wrapper md-pt-50">
            <div className="title-style-seven">
              <h2>
                <span>Become</span> <br />
                SAP BTP Ninja!
              </h2>
            </div>
            {/* /.title-style-seven */}
            <div className="hero-banner-seven lg-container">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* <FormDemo /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.text-wrapper */}
        </div>
        {/* End .col */}

        <div
          className="col-xl-7 col-lg-6 col-md-8 m-auto order-lg-first"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="illustration-holder">
            <img src="images/assets/ils_21.svg" alt="about" />
          </div>
        </div>
        {/* End .col */}
      </div>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="FDaF8_5dzzk"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default FancyTextBlock22;
