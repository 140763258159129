import React from "react";
import FormDemo from "../../components/form/FormDemo";
import BrandFour from "../brand/BrandFour";

const HeroBannerSeven = () => {
  return (
    <div className="hero-banner-seven lg-container">
      <div className="container">
        <div className="illustration-container">
          <img
            src="images/assets/book.png"
            alt="ils"
            style={{ marginTop: "-10%", marginLeft: "5%", maxWidth: "65%" }}
          />
        </div>
        {/* End .illustration-container */}

        <div className="row">
          <div className="col-lg-6">
            <h1 className="hero-heading">
              <span>Build</span>, Deploy & Extend.
            </h1>
            <p className="hero-sub-heading">
              Coherent guide on how to deliver business applications in{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>SAP BTP</span>,
              Cloud Foundry runtime using: UI5 Web Components, React.js,
              Node.js, HANA Cloud and more...
            </p>
            {/* <FormDemo /> */}
            <p className="term-text">
              {/* For teams & individuals — web, mobile, Mac, Windows */}
            </p>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}

      <div className="partner-slider-two mt-130 md-mt-80">
        <div className="container">
          <p className="text-center">
            Read by <span>experts</span> working at:
          </p>
          <div className="partnerSliderTwo">
            <BrandFour />
          </div>
        </div>
      </div>
      {/* End .partner-slider-two */}

      {/* /.partner-slider-two  */}
    </div>
  );
};

export default HeroBannerSeven;
