import React from "react";
import { Link } from "react-router-dom";
import FormFooterSignup from "../form/FormFooterSignup";

const FooterSeven = () => {
  return (
    <div className="row ">
      <div
        className="col-xl-3 col-lg-2 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="logo">
          <h3 style={{ fontWeight: "500" }}>btp.ninja</h3>
          <h6>Learn to deliver apps in SAP BTP</h6>
        </div>
      </div>
      {/* End .col 
      <div
        className="col-lg-2 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="title">Links</h5>
        <ul className="footer-list">
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </div>
      */}
        

      <div
        // className="col-lg-3 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        {/* End .col 
        <h5 className="title">Legal</h5>
        <ul className="footer-list">
          <li>
            <Link to="/terms-conditions">Terms of use</Link>
          </li>
          <li>
            <Link to="/terms-conditions">Terms & conditions</Link>
          </li>
          <li>
            <Link to="/terms-conditions">Privacy policy</Link>
          </li>
          <li>
            <Link to="/terms-conditions">Cookie policy</Link>
          </li>
        </ul>
           */}
      </div>
      {/* 
      <div
        className="col-xl-6 col-lg-8 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <div className="newsletter">
          <h5 className="title">Newsletter</h5>
          <p>Join community of SAP Experts</p>

          <FormFooterSignup />

          <div className="info">Only interesting and relevant emails.</div>
        </div>
        /.newsletter 
      </div>
      */}
    </div>
  );
};

export default FooterSeven;
