/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import useDocumentTitle from "../../components/useDocumentTitle";
import FancyTextBlock22 from "../../components/fancy-text-block/FancyTextBlock22";
import HeaderLandingNoteTaking from "../../components/header/landing/HeaderLandingNoteTaking";
import HeroBannerSeven from "../../components/hero-banner/HeroBannerSeven";
import PricingSix from "../../components/pricing/pricing-six/Pricing";
// import TestimonialSix from "../../components/testimonial/TestimonialSix";
import CopyRightThree from "../../components/footer/CopyRightThree";
import FooterSeven from "../../components/footer/FooterSeven";
// import FancyFeatureSeventeen from "../../components/features/FancyFeatureSeventeen";
import DocAsTabs from "../../components/block-slider/DocAsTabs";

const NoteTakingLanding = () => {
  useDocumentTitle(
    "btp.ninja | Learn to deliver apps in SAP BTP Cloud Foundry Runtime"
  );
  const history = useNavigate();
  // For header select menu
  const click1 = false;

  const handleClick1 = () => {
    // history.push("/#pricing");
  };

  return (
    <div className="main-page-wrapper font-gordita">
      <HeaderLandingNoteTaking />
      {/* End Header Landing Note Talking */}

      {/* 	=============================================
				Theme Hero Banner
			==============================================  */}
      <HeroBannerSeven />

      {/* 	=============================================
				Fancy Feature Sixteen
			==============================================  */}
      <div className="fancy-feature-sixteen pt-200 md-pt-100" id="about">
        <div className="container">
          <div className="block-style-eighteen">
            <div className="row align-items-center">
              <div
                className="col-lg-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Brace Yourself</h6>
                  <h3 className="title">
                    <span>Prepare</span> development environment
                  </h3>
                  <p>
                    Get access to SAP BTP and SAP HANA Cloud. Install required
                    plugins such as cf, mbt, htm5-plugin, ui5-tooling. Configure
                    Visual Studio Code to boost your coding!
                  </p>
                </div>
                {/*  /.text-wrapper */}
              </div>
              <div
                className="col-lg-7"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div
                  className="
                    screen-holder-one
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <div
                    className="
                      round-bg
                      big_g
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    style={{ width: "193px", height: "193px" }}
                  >
                    <img src="images/logo/vscode.png" alt="logo" />
                  </div>
                  <div
                    className="
                      round-bg
                      d-flex
                      align-items-center
                      justify-content-center
                      shapes
                      logo-one
                    "
                    style={{ width: "65px", height: "65px" }}
                  >
                    <img src="images/logo/cli.png" alt="logo" />
                  </div>
                  <div
                    className="
                      round-bg
                      d-flex
                      align-items-center
                      justify-content-center
                      shapes
                      logo-two
                    "
                    style={{ width: "105px", height: "105px" }}
                  >
                    <img src="images/logo/cf.png" alt="logo" />
                  </div>
                  <div
                    className="
                      round-bg
                      d-flex
                      align-items-center
                      justify-content-center
                      shapes
                      logo-three
                    "
                    style={{ width: "81px", height: "81px" }}
                  >
                    <img src="images/logo/sap-hana-service.png" alt="logo" />
                  </div>
                  <div
                    className="
                      round-bg
                      d-flex
                      align-items-center
                      justify-content-center
                      shapes
                      logo-four
                    "
                    style={{ width: "148px", height: "148px" }}
                  >
                    <img src="images/logo/oui.png" alt="logo" />
                  </div>
                  <div
                    className="
                      round-bg
                      d-flex
                      align-items-center
                      justify-content-center
                      shapes
                      logo-five
                    "
                    style={{ width: "148px", height: "148px" }}
                  >
                    <img src="images/logo/btp.jpg" alt="logo" />
                  </div>
                  <img
                    src="images/shape/170.svg"
                    alt=""
                    className="shapes shape-one"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.block-style-eighteen */}

          <div className="block-style-eighteen mt-200 pt-50 md-mt-80">
            <div className="row align-items-center">
              <div
                className="col-lg-5 order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Build Frontend & Backend Applications</h6>
                  <h3 className="title">
                    <span>Build</span>
                    <br />
                    from scratch
                  </h3>
                  <p>
                    Follow step-by-step instructions to build applications using
                    different technologies and libraries like: JavaScript, UI5
                    Web Components, Node.js, React.js and SAPUI5.
                  </p>
                </div>
                {/* 
                  <Link
                    to="/product-customer-support"
                    className="d-flex align-items-center learn-more"
                  >
                    <span>Learn More</span>
                    <img src="images/icon/93.svg" alt="screen" />
                  </Link>
                
                /.text-wrapper */}
              </div>
              <div
                className="col-lg-7 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="screen-holder-two">
                  <img src="images/book/ui5_web_components.png" alt="screen" />
                  <img
                    src="images/book/ui5_web_components_ui.png"
                    alt=""
                    className="shapes screen-one"
                    style={{ marginRight: "30%" }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /.block-style-eighteen */}

          <div className="block-style-eighteen mt-200 pt-50 md-mt-80">
            <div className="row align-items-center">
              <div
                className="col-lg-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>SAP Specific tools</h6>
                  <h3 className="title">
                    HDI & XSUAA <span>explained</span>
                  </h3>
                  <p>
                    Learn how to use HDI Containers to unleash the power of the
                    SAP HANA Database in your applications. Keep your apps safe
                    using XSUAA.
                  </p>
                </div>
                {/* 
                  <Link
                    to="/product-customer-support"
                    className="d-flex align-items-center learn-more"
                  >
                    <span>Learn More</span>
                    <img src="images/icon/93.svg" alt="screen" />
                  </Link>
                  </div>
               /.text-wrapper */}
              </div>
              <div
                className="col-lg-7"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div
                  className="
                    screen-holder-two
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <img src="images/book/hana-client.png" alt="screen" />
                </div>
              </div>
            </div>
          </div>
          {/* /.block-style-eighteen */}
        </div>
      </div>
      {/*  /.fancy-feature-sixteen */}

      {/* =============================================
				Fancy Feature Fifteen
			==============================================  */}
      <div className="fancy-feature-fifteen pt-110 md-pt-80" id="preview">
        <div className="bg-wrapper">
          <div className="container">
            <div className="sldier-wrapper">
              <DocAsTabs />
            </div>
            {/* /.sldier-wrapper */}
          </div>
          <img
            src="images/shape/168.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/169.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/*  /.fancy-feature-fifteen */}

      {/* =====================================================
				Fancy Text Block Twenty Two
			===================================================== */}
      <div
        className="fancy-text-block-twentyTwo lg-container pt-200 md-pt-120"
        id="free"
      >
        <div className="container">
          <FancyTextBlock22 />
        </div>
      </div>
      {/* /.fancy-text-block-twentyTwo */}

      {/* =====================================================
				Fancy Feature Seventeen
			===================================================== */}
      {/*
      <div className="fancy-feature-seventeen mt-150 md-mt-90" id="product">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <FancyFeatureSeventeen />
            </div>
          </div>
        </div>
      </div>*/}

      {/* /.fancy-feature-seventeen */}

      {/* 	=====================================================
				Pricing Section Six
			===================================================== */}
      <div className="pricing-section-six pt-25 md-pt-25" id="pricing">
        <PricingSix />
      </div>
      {/* /.pricing-section-six */}

      {/* =====================================================
				Client Feedback Slider Six
			===================================================== */}
      <div
        className="client-feedback-slider-six pt-200 md-pt-120"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img src="images/icon/04.svg" alt="icon" className="m-auto" />
                <div className="title-style-seven text-center mt-20">
                  <h2>
                    <span>Thank You</span>
                  </h2>
                  <p>Kind words from readers.</p>
                </div>
                {/* /.title-style-six  */}
              </div>
            </div>
          </div>
          <div className="clientSliderSix">
            
          </div>
        </div>
        {/* /.inner-container */}
      </div>
      {/* /.client-feedback-slider-six */}

      {/* 	=====================================================
				Fancy Short Banner Eight
			===================================================== */}
      <div className="fancy-short-banner-eight mt-170 md-mt-80">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-seven text-center">
                <h2>
                  <span>395+</span> readers so far 💪
                </h2>
                <p>#selfpublishing</p>
              </div>
              {/*  /.title-style-six */}
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          ></div>
        </div>
        {/* /.container */}
        <img
          src="images/shape/171.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/172.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/*  /.fancy-short-banner-eight */}

      {/* 			=====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default NoteTakingLanding;
