import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PricingMonthly from "./PricingMonthly";
import PricingYearly from "./PricingYearly";

const Pricing = () => {
  return (
    <div className="pricing-table-area-six">
      <PricingMonthly />
    </div>
  );
};

export default Pricing;
